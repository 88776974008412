import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import DeleteIcon from './../../../svg-components/DeleteIcon';
import JoinIcon from './../../../svg-components/JoinIcon';
import Button from './../../../components/button/Button';
import PageTitle from './../../../components/page-title/PageTitle';
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Categories = (props) => {
    let [ load, setLoad ] = useState(false);
    let categories = useSelector(state => state.categories);
    let lang = useSelector(state => state.lang);
    let [ categoriesData, setCategoriesData ] = useState([]);
    let [ rendering, setRendering ] = useState(null);
    let [ removeItems, setRemoveItems ] = useState([]);
    let [ newItemName, setNewItemName ] = useState('');
    let [ newSelectCategoryIndex, setNewSelectCategoryIndex ] = useState(0);
    let [ newNumber, setNewNumer ] = useState(0);
    let history = useHistory();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(categories.length > 0 && !load) {
            createDataOnLoad();
            setLoad(true);
        }
    }, [load, categories])

    useEffect(() => {
        if(load) {
            createDataOnLoad();
        }
    }, [lang])
    console.log(categoriesData[3])
    const createDataOnLoad = () => {
        let newData = categories;
        newData && newData.map((cat, index) => {
            let childrens = [];
            cat.childrens && cat.childrens.map((item, i) => {
                if(lang.id && item.language == lang.id) {
                    let newItem = {
                        id: item.id,
                        name: item.name,
                        parent: item.parent,
                        order: item.order,
                        language: item.language,
                        update: false,
                    }
                    childrens = [...childrens, newItem]
                }
            })
            cat.items = childrens;
        })
        setCategoriesData(newData);
        setRendering(Math.random())
    }

    const save = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/saveCategories`,
            data: {
                token: localStorage.getItem('token'),
                data: categoriesData,
                removeItems: removeItems,
                langID: lang.id,
                userID: localStorage.getItem('userId'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status = "success") {
                // dispatch({ type: 'MENUS', value: res.data })
                dispatch({ type: 'CATEGORIES', value: res.data })
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Kategorie zostały zaaktualizowane</p>` }) 
            }else{
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
        })
    }

    const update = (categoryIndex, itemIndex, newValue, label) => {
        let newCategories = categoriesData;
        let newCategoriesItem = null;
        newCategoriesItem = (newCategories[categoryIndex].items).filter((item, i) => i === itemIndex ? (item[label] = newValue, item['update'] = true) : item);
        newCategories[categoryIndex].items = newCategoriesItem;
        setCategoriesData(newCategories);
        setRendering(Math.random())
    }

    const remove = (item, itemIndex, parentIndex) => {
        let newArray = categoriesData
        let newItems;
        newItems = (newArray[parentIndex].items).filter((item, i) => i !== itemIndex);
        newArray[parentIndex].items = newItems;
        setCategoriesData(newArray);
        if(!(String(item.id)).includes('new')) { 
            setRemoveItems([...removeItems, item.id])
        }
        setRendering(Math.random())
    }

    const addItem = () => {
        let inputName = document.getElementById('inputName');
        if(newItemName.length > 0) {
            inputName.classList.remove('error')
            let newCategoriesData = categoriesData;
            let newItem = {
                id: `new-${newNumber}`,
                name: newItemName,
                parent: categoriesData[newSelectCategoryIndex].id,
                update: true,
            }
            let newItems = [...categoriesData[newSelectCategoryIndex].items, newItem];
            newCategoriesData[newSelectCategoryIndex].items = newItems;
            setCategoriesData(newCategoriesData)
            setNewNumer(newNumber + 1);
            setNewItemName('');
            setRendering(Math.random())
        }else{
            inputName.classList.add('error')
        }
    }

    //for INDEX 3 (KATEGORIE MARKETING SUPPORT)
    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(categoriesData[3].items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log(items)
        let newCategoriesData = categoriesData;
        newCategoriesData[3].items = items;
        setCategoriesData(newCategoriesData);
        // newCategoriesData.filter((i, item) => 
        //     i == 3 {
        //         item[3]
        //     }
        // )
    }

    return (
        <div className="container categories-section">
            <PageTitle title="Kategorie" />
            <div className="categories-wrapper">
                <div className="category-boxes">
                    {categoriesData && categoriesData.map((cat, i) => (
                        <div className={newSelectCategoryIndex == i ? "category check" : "category"} key={cat.id}>
                            <div className="header" onClick={() => setNewSelectCategoryIndex(i)}>
                                <span>{cat.name}</span>
                            </div>
                            <div className="content">
                                {i == 3 && cat.items &&
                                    <DragDropContext onDragEnd={handleRLDDChange}>
                                        <Droppable droppableId="sections-component">
                                        {(provided) => (
                                            <div className="sections-component" {...provided.droppableProps} ref={provided.innerRef}>
                                                {cat.items.map((children, index) => (
                                                    <Draggable key={String(children.id)} draggableId={String(children.id)} index={index}>
                                                        {(provided) => provided && (
                                                            <div className="row-item" {...provided.draggableProps} ref={provided.innerRef}>
                                                                <div className="header-item">
                                                                    <input className="category-input" type="text" value={children.name} onChange={(e) => update(i, index, e.target.value, "name")} />
                                                                    <div className="svg-icon" onClick={() => remove(children, index, i)}>
                                                                        <DeleteIcon />
                                                                    </div>
                                                                    <div className="svg-icon" onClick={() => history.push(`/dashboard/categories/page/${children.id}`)}>
                                                                        <JoinIcon />
                                                                    </div>
                                                                    <i {...provided.dragHandleProps} 
                                                                        className="material-icons"
                                                                        aria-label="Przeciągnij sekcje"
                                                                        tabIndex="0"
                                                                        role="button">open_with
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                }
                                {i != 3 &&
                                    <>
                                    {cat.items && cat.items.map((children, index) => (
                                        <div className="row-item">
                                            <div className="header-item">
                                                <input className="category-input" type="text" value={children.name} onChange={(e) => update(i, index, e.target.value, "name")} />
                                                <div className="svg-icon" onClick={() => remove(children, index, i)}>
                                                    <DeleteIcon />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className="create-new-item">
                    <label>Nazwa</label>
                    <input type="text" id="inputName" value={newItemName} onChange={(e) => setNewItemName(e.target.value)} className="create" />
                    <Button title="Dodaj" setFunction={() => addItem()} />
                </div>
                <div className="save">
                    <Button title="Zapisz zmiany" setFunction={() => save()} />
                </div>
            </div>
        </div>     
    );
}

export default Categories;